import { ReactComponent as TwitterImg } from '@/assets/twitter.svg';
import { ReactComponent as GithubImg } from '@/assets/github.svg';
import { ReactComponent as MediumImg } from '@/assets/medium.svg';

function Footer() {
  const medias = [
    {
      link: 'https://twitter.com/Smart__Safe',
      icon: <TwitterImg />,
    },
    {
      link: 'https://medium.com/@smartsafecoredevs',
      icon: <MediumImg />,
    },
    {
      link: 'https://github.com/smart-safe',
      icon: <GithubImg />,
    },
  ];
  return (
    <footer className="px-4 py-8 md:px-8">
      <div className="mx-auto flex max-w-7xl items-center justify-between text-gray-500">
        <div className="flex items-center justify-between gap-4">
          <span className="text-xs">@ 2023 SmartSafe</span>
        </div>
        <div className="flex items-center gap-4">
          {medias.map((media, index) => (
            <a className="h-6 w-6" href={media.link} target="_blank" rel="nofollow noopener noreferrer" key={index}>
              {media.icon}
            </a>
          ))}
        </div>
      </div>
    </footer>
  );
}

export default Footer;

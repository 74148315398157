import LinkButton from '@/components/LinkButton';
import { ReactComponent as ForwardImg } from '@/assets/forward.svg';

function Home() {
  return (
    <div className="flex grow flex-col items-center justify-center">
      <h2 className="mx-auto -mt-8 max-w-3xl bg-gradient-to-b from-[#14CD68] to-[#035C7D] bg-clip-text text-center text-4xl font-bold text-transparent lg:text-6xl">
        A Secure and Intelligent Digital Asset Management Solution
      </h2>
      <div className="mt-8 text-center">
        <LinkButton href="https://app.smartsafe.one">
          <i className="h-4 w-4 text-white">
            <ForwardImg />
          </i>
          <span className="ml-2">Launch App</span>
        </LinkButton>
      </div>
    </div>
  );
}

export default Home;

import { useState, useEffect } from 'react';
import LinkButton from '../LinkButton';

function Header() {
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const onScroll = () => setOffset((window.pageYOffset > 80 ? 1 : window.pageYOffset / 80) * 1.5);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const style = {
    backgroundColor: `rgba(17, 24, 39, ${offset})`,
  };

  return (
    <header className="sticky top-0 z-30 border-b border-slate-800 px-4 py-4 md:px-8 lg:border-b-0" style={style}>
      <div className="mx-auto flex max-w-7xl items-center justify-between">
        <div className="flex items-center justify-between gap-4">
          <a className="cursor-pointer select-none text-2xl font-bold" href={'/'}>
            <span className="bg-gradient-to-r from-[#007BD3] to-[#034373] bg-clip-text text-transparent">Smart</span>
            <span className="ml-1 bg-gradient-to-r from-[#14CD68] to-[#0B6E38] bg-clip-text text-transparent">
              Safe
            </span>
          </a>
        </div>
        <div className="flex items-center gap-4">
          <LinkButton href="https://app.smartsafe.one">Launch App</LinkButton>
        </div>
      </div>
    </header>
  );
}

export default Header;

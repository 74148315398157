import classNames from 'classnames';

function LinkButton(props) {
  const { children, className, href } = props;
  return (
    <a
      className={classNames(
        'inline-flex cursor-pointer select-none items-center rounded-md bg-[#14CD68] px-4 py-3 text-base font-bold text-white hover:bg-opacity-70',
        className
      )}
      href={href}
      target="_blank"
      rel="nofollow noopener noreferrer"
    >
      {children}
    </a>
  );
}

export default LinkButton;

import { Routes, Route } from 'react-router-dom';
import classNames from 'classnames';

import './App.css';

import Header from '@/components/Header';
import Home from './home';
import Footer from '@/components/Footer';

// #007BD3 #034373 #14CD68 #0B6E38
// 14CD68 035C7D

function App() {
  return (
    <div className={classNames('flex flex-col', 'min-safe-h-screen')}>
      <Header />
      <main className="mx-auto flex max-w-7xl grow flex-col items-center justify-center p-4 md:p-8">
        <Routes>
          <Route path="/">
            <Route index element={<Home />} />
          </Route>
        </Routes>
      </main>
      <Footer />
      <div className="pointer-events-none fixed top-0 left-0 -z-[1] h-[200vh] w-[200vw] translate-x-[-50vw] translate-y-[-100vh] bg-gradient-radial from-slate-800 to-slate-900"></div>
    </div>
  );
}

export default App;
